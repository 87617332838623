<template>
    <div class="my-3">
        <div class="w-100">
            <h1 class="h3 text-center">
                Blogs encontrados
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>
        </div>
        <div class="d-flex justify-content-between flex-column flex-lg-row">
            <div class="my-3 d-flex justify-content-around">
                <vs-button relief @click="reloadItems">
                    Consultar 🔎
                </vs-button>
            </div>

            <div class="my-3 d-flex align-items-center justify-content-around">
                <vs-pagination
                    v-model="currentPage"
                    :length="pagesNumber"
                    @input="listItems()"
                />
                <tooltip-button
                    v-show="selected.length"
                    icon="🧹"
                    message="Limpiar selección"
                    @click="clearSelected"
                />
                <tooltip-button
                    v-show="selected.length && $ability.hasScope('blog:Create')"
                    icon="©"
                    message="Clonar Blog"
                    @click="cloneSelected"
                />
                <tooltip-redirect
                    v-if="$ability.hasScope('blog:Create')"
                    to="/app/blogs/new"
                    message="Nuevo Blog"
                >
                </tooltip-redirect>
            </div>
        </div>
        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="single"
                selectable
                outlined
                responsive
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(slug)="data">
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="`/app/blogs/detail/${data.item.slug}`"
                            class="text-center font-weight-normal btn btn-primary p-1"
                        >
                            <div class="d-flex align-items-center">
                                <small>
                                    {{ data.item.active ? "🟢" : "⚪" }}
                                    {{ data.item.slug }}
                                </small>
                            </div>
                        </router-link>
                    </div>
                </template>
                <template #cell(title)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.title }}
                    </small>
                </template>

                <template #cell(shortDescription)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.shortDescription }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "BlogsIndex",
    components: { TooltipRedirect, TooltipButton },
    layout: "app",
    data: () => ({
        loaderInstance: null,
        keySearch: "",
        itemsCurrent: [],
        selected: [],
        $fields: [
            { key: "slug", label: "Slug", sortable: true },
            { key: "title", label: "Titulo", sortable: true },
            {
                key: "shortDescription",
                label: "Descripción",
                sortable: false
            }
        ],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        await verifyPageScope("blog:List", "/app");
        this.listItems(true);
    },
    methods: {
        ...mapActions("blogs", ["listBlogsPaginated"]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const { items, pagination } = await this.listBlogsPaginated({
                    page: this.currentPage,
                    complete
                });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        onRowSelected(items) {
            this.selected = items;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        },
        cloneSelected() {
            window
                .open(`/app/blogs/clone/${this.selected[0].id}`, "_blank")
                .focus();
        }
    }
};
</script>
