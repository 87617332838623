<template>
    <div>
        <vs-tooltip v-model="isOpen" border>
            <vs-button circle icon floating :loading="loading" @click="onClick">
                {{ icon }}
            </vs-button>
            <template #tooltip> {{ message }} </template>
        </vs-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        message: { default: "Nuevo", type: String, required: true },
        icon: { default: "🔁", type: String, required: false },
        loading: { default: false, type: Boolean, required: false }
    },
    emits: ["click"],
    data: () => ({ isOpen: false, enabledRedirect: false }),
    methods: {
        onClick() {
            if (this.isOpen) {
                this.isOpen = false;
            } else {
                this.isOpen = true;
                setTimeout(() => (this.isOpen = false), 2000);
            }
            this.$emit("click");
        }
    }
};
</script>
